import styles from "./Formulario.module.css";
import whatsappLogo from 'assets/WhatsAppButtonGreenLarge.png';

export default function Formulario() {
    
    return (
        <section className={styles.formContainer}>
            <form className={styles.formulario}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>              
                 <h1><a href="https://www.facebook.com/profile.php?id=100081668980226" target="_blank" rel="noreferrer">Acesse meu facebook</a></h1>
                 <h1><a href="https://www.instagram.com/veronesetom" target="_blank" rel="noreferrer">Acesse meu instagram</a></h1>
                 <h1><a href="https://www.linkedin.com/in/tomveronese/" target="_blank" rel="noreferrer">Acesse meu Linkedin</a></h1>
                 <a aria-label="Chat on WhatsApp" href="https://wa.me/5516992817641" target="_blank" rel="noreferrer">
                 
                <img 
                    src={whatsappLogo}
                    aria-hidden={true}
                    alt='Chat on WhatsApp'
                />
                </a>
            </div>
            </form>
        </section>
    )
}