import styles from './Banner.module.css';

export default function Banner() {
    return (
        <div className={styles.banner}>
            <div className={styles.apresentacao}>
                <h1 className={styles.titulo}>
                    Seja bem-vindo!
                </h1>
                <p className={styles.paragrafo}>
                    Boas vindas ao meu espaço pessoal!
                </p>
            </div>
    </div>
    )
}