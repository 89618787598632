import Formulario from "componentes/Formulario";
import styles from "./Contato.module.css";

export default function Contato() {
    
    return (
    <section className={styles.container}>
        <Formulario />
    </section>
    )
}