import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./paginas/Inicio"
import SobreMim from "./paginas/SobreMim";
import Post from "./paginas/Post";
import Menu from "componentes/Menu";
import Rodape from "componentes/Rodape";
import PaginaPadrao from "componentes/PaginaPadrao";
import NaoEncontrada from "paginas/NaoEncontrada";
import ScrollToTop from "componentes/ScrollToTop";
import Blog from "paginas/Blog";
import Curriculo from "paginas/Curriculo";
import Contato from "paginas/Contato";

function AppRoutes() {
  return (
  <BrowserRouter>
    <ScrollToTop/>
    <Menu />

    <Routes>
      <Route index path="/" element={<Inicio />} />
      <Route path="/" element={<PaginaPadrao />}>
        <Route path="blog" element={<Blog />} />
        <Route path="curriculo" element={<Curriculo />} />
        <Route path="contato" element={<Contato />} />
        <Route path="sobremim" element={<SobreMim />} />
      </Route>

      <Route path="posts/:id" element={<Post/>} />
      <Route path="*" element={<NaoEncontrada/>} />
    </Routes>

    <Rodape />
  </BrowserRouter>
  )
}

export default AppRoutes;
