import Experiencia from "componentes/Experiencia";
import Formacao from "componentes/Formacao";
import styles from "./Curriculo.module.css";
import experiencias from "json/curriculo-trabalho.json";
import formacoes from "json/formacao.json";

export default function Curriculo () {
    const trabalhos = experiencias.sort((a, b) => (a.id > b.id) ? 1 : -1).reverse();
    const minhasFormacoes = formacoes.sort((a, b) => (a.id > b.id) ? 1 : -1);
    return (
        <section>
            <h2 className={styles.titulo}>Meu Currículo</h2>
            
            <div className={styles.container}>
                <h3>Formação Acadêmica</h3>
                <ul>
                    {minhasFormacoes.map((formacao) => (
                        <li key={minhasFormacoes.id}>
                            <Formacao formacao={formacao} />
                        </li>
                    ))}
                </ul>

                <h3>Experiência Profissional</h3>
                <ul>
                    {trabalhos.map((trabalho) => (
                        <li key={trabalhos.id}>
                            <Experiencia trabalho={trabalho} />
                        </li>
                    ))}
                </ul>
            </div>
            
        </section>
    )
}