import styles from './SobreMim.module.css'

import PostModelo from "componentes/PostModelo"
import fotoCapa from "assets/sobre_mim_capa.png"
import fotoSobreMim from "assets/sobre_mim_foto.png"

export default function SobreMim() {
    return (
        <PostModelo
            fotoCapa={fotoCapa}
            titulo="Sobre mim"
        >   
            <h3 className={styles.subtitulo}>
                Olá, eu sou o Everton!
            </h3>
            
            <img
                src={fotoSobreMim}
                alt="Foto do Everton Veronese sorrindo"
                className={styles.fotoSobreMim}
            />
            
            <p className={styles.paragrafo}>
            Sou Everton Santos Veronese, nascido em Guará, em 1986, fiz meu ensino fundamental na escola Helena Telles Furtado e na escola 
            Rondom estudei até o fim do ensino médio.
            </p>
            <p className={styles.paragrafo}>
            Tive a oportunidade de fazer um curso de técnico em informática na ETEC Alcídio de Souza Prado.
            </p>
            <p className={styles.paragrafo}>
            Antes de concluir meu ensino médio tive a oportunidade de trabalhar como office-boy na OAB de Guará, localizada no Fórum municipal.
            e ao concluir o curso técnico, comecei a trabalhar como Professor de informática, trabalhei em escolas de: São Joaquim da barra, na escola Web informática, Guará, na escola Solução mais, Franca e Ituverava, na escola Microlins
            </p>
            <p className={styles.paragrafo}>
            Em 2009 me mudei para Franca e comecei minha carreira como programador na empresa Betta Tecnologia, onde fiquei até o ano de 2017
            </p>
            <p className={styles.paragrafo}>
            No final do ano de 2017 concluí o curso superior de Análise e desenvolvimento de sistemas na FATEC Franca.
            </p>
            <p className={styles.paragrafo}>
            Retornei para Guará, onde comecei a trabalhar na empresa ACE-DATA de São Joaquim da Barra, até 2019.
            </p>
            <p className={styles.paragrafo}>
            Em 2019 tive a oportunidade de trabalhar com a empresa Pentare Consultoria da cidade de Ribeirão Preto no formato Remoto, onde trabalhei até Outubro de 2023.
            </p>
            <p className={styles.paragrafo}>
            Hoje voltei a dar aula de informática e vocês pode solicitar meus serviços
            </p>
        </PostModelo>
    )
}