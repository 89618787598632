import styles from './Experiencia.module.css';
import { format } from 'date-fns';

export default function Experiencia ({ trabalho }) {
    
    let dataInicio = `${format( trabalho.inicio, 'MMMM')} de ${format(trabalho.inicio, 'yyyy')}`;
    let dataFim = '';

    if (trabalho.fim !== ''){
        dataFim = `${format(trabalho.fim, 'MMMM')} de ${format(trabalho.fim, 'yyyy')}`;
    }

    return (
        <div className={styles.experiencia}>
            <div className={styles.sessaoImagem}>
                <a href={trabalho.link_empresa} target='_blank' rel='noreferrer'>
                    <img 
                        src={trabalho.logo_empresa} 
                        className={styles.imagem}
                        alt={`Logo`} 
                    />
                </a>                
            </div>
            <div className={styles.sessaoCargo}>
                <span className={styles.cargo}>{trabalho.cargo}</span>
                <span className={styles.empresa}><a href={trabalho.link_empresa} target='_window'>{trabalho.empresa}</a></span>
                <span className={styles.cidade}>{trabalho.cidade} • {trabalho.modalidade}</span>
                <span className={styles.periodo}>{dataInicio} até {dataFim}</span>
                <span className={styles.atividades}>{trabalho.atividades}</span>
            </div>
        </div>
    )
}