import styles from "./Menu.module.css";
import MenuLink from "../MenuLink";

export default function Menu() {

    return (
        <header>
            <nav className={styles.navegacao}>
                <MenuLink to="/">Início</MenuLink>
                <MenuLink to="/blog">Blog</MenuLink>
                <MenuLink to="/curriculo">Currículo</MenuLink>
                <MenuLink to="/contato">Contato</MenuLink>
                <MenuLink to="/sobremim">Sobre mim</MenuLink>
            </nav>
        </header>
    )
}