import styles from "./Inicio.module.css";
import circuloColorido from 'assets/circulo_colorido.png';
import minhaFoto from 'assets/minha_foto.png';

export default function Inicio() {
    return (
        <div className={styles.banner}>
            <div className={styles.apresentacao}>
                <h1 className={styles.titulo}>
                    Seja bem-vindo!
                </h1>
                <p className={styles.paragrafo}>
                    Boas vindas ao meu espaço pessoal! Eu sou Everton Santos Veronese, professor de informática. Aqui compartilho vários conhecimentos, espero que aprenda algo novo :)
                </p>
            </div>

            <div className={styles.imagens}>
                <img 
                    className={styles.circuloColorido}
                    src={circuloColorido}
                    aria-hidden={true}
                    alt='circulo'
                />
                <img 
                    className={styles.minhaFoto}
                    src={minhaFoto}
                    alt="Minha foto sorrindo"
                />
            </div>

    <div className={styles.star}>
        <span className={styles.starfive} width="10" height="10">
        </span>

        <div className={styles.staroutline}>
            <span className={styles.starfive}>
            </span>
        </div>
    </div>
</div>
    )
}