import styles from './Formacao.module.css';
import { format } from 'date-fns';

export default function Formacao ({ formacao }) {
    
    let dataInicio = `${format( formacao.inicio, 'MMMM')} de ${format(formacao.inicio, 'yyyy')}`;
    let dataFim = '';

    if (formacao.fim !== ''){
        dataFim = `${format(formacao.fim, 'MMMM')} de ${format(formacao.fim, 'yyyy')}`;
    }

    return (
        <div className={styles.formacao}>
            <div className={styles.sessaoImagem}>
                <a href={formacao.link_formacao} target='_blank' rel='noreferrer'>
                    <img 
                        src={formacao.logo_formacao} 
                        className={styles.imagem}
                        alt={`Logo`} 
                    />
                </a>                
            </div>
            <div className={styles.sessaoFormacao}>
                <span className={styles.curso}>{formacao.curso}</span>
                <span className={styles.empresa}><a href={formacao.link_formacao} target='_window'>{formacao.instituicao}</a></span>
                <span className={styles.cidade}>{formacao.cidade} • {formacao.modalidade}</span>
                <span className={styles.periodo}>{dataInicio} até {dataFim}</span>
                <span className={styles.descricao}>{formacao.descricao}</span>
            </div>
        </div>
    )
}